.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 6;
}

.spinner-style {
    width: 200px;
    height: 200px;
    background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
    z-index: 6;

    img {
        width: 150px;
        height: 150px;
    }
}
