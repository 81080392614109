/*===================
Button Area 
====================*/
a {
    &.btn-transparent {
        color: #1f1f25;
        @extend %transition;
        font-size: 16px;
        font-weight: 500;
        position: relative;
        display: inline-block;
        padding-bottom: 3px;
        &:hover {
            color: $theme-color;
        }
        &::after {
            position: absolute;
            content: "";
            left: 0;
            bottom: 0;
            width: 40px;
            height: 2px;
            background: $theme-color;
            opacity: 0.75;
            @extend %transition;
        }
        &:hover {
            &::after {
                width: 100%;
            }
        }
    }
}

a,
button {
    &.rn-btn {
        padding: 0 23px;
        height: 40px;
        display: inline-block;
        line-height: 34px;
        border: 2px solid $theme-color;
        border-radius: 4px;
        font-size: 14px;
        position: relative;
        z-index: 2;
        // font-family: Montserrat;
        color: $theme-color;
        letter-spacing: 0.2px;
        @extend %transition;
        text-transform: uppercase;
        &:hover {
            border: 2px solid $theme-color;
            transform: translateY(-3px);
            color: #ffffff;
            background: $theme-color;
        }
        &.btn-white {
            border: 2px solid #ffffff;
            color: #ffffff;
            &:hover {
                border-color: $theme-color;
            }
        }
    }
}

button,
a {
    &.rn-button-style--2 {
        color: #c6c9d8;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2px;
        border: 2px solid #b1b4c1;
        padding: 15px 40px;
        border-radius: 6px;
        display: inline-block;
        font-weight: 500;
        transition: 0.3s;
        @media #{$sm-layout} {
            padding: 9px 25px;
        }
        &.rn-btn-dark {
            border: 2px solid #1f1f25;
            color: #1f1f25;
            &:hover {
                background: #1f1f25;
                color: #fff;
            }
        }
        &.border-opacity-half {
            border-color: rgba(31, 31, 37, 0.1);
        }
        &.rn-btn-small {
            padding: 10px 20px;
        }
        &.btn-primary-color {
            &:hover {
                border: 2px solid $theme-color;
                background: $theme-color;
                color: #ffffff;
            }
        }
        &.btn-solid {
            border: 2px solid $theme-color;
            background: $theme-color;
            color: #ffffff;
        }
        &.btn-solid {
            background: $theme-color;
            color: #ffffff;
        }
        &:hover {
            background: transparent;
            color: #f9004d;
            box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
            transform: translateY(-5px);
        }
    }
}
