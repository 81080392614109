.sc-header {
    background-color: #f9004d;
}

.sc-header--team-name {
    font-size: 24px;
}

.sc-header--img {
    border-radius: 50%;
    align-self: center;
    padding: 10px;
    width: 80px;
    height: 80px;
}

.sc-message--avatar {
    background-image: url("/assets/images/profile.jpeg") !important;
}

.sc-launcher {
    background-color: #f9004d;
}

.sc-message--content.sent .sc-message--text {
    background-color: whitesmoke;
    color: black;
}

.sc-chat-window {
    z-index: 99;
}

.sc-header--close-button:hover {
    background: transparent;
}
