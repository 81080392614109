// Here you can add other styles
.c-sidebar .c-sidebar-nav-link:hover,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: black;
    background: white;
}

.customSidebar {
    background: linear-gradient(-259deg, #f81f01 0, #ee076e 100%);
}

h2 {
    font-size: 20px;
}
