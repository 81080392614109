/*====================
    hero-section Area 
======================*/

@media #{$sm-layout} {
    .bg_image--31 {
        background-position: 67% center !important;
    }
    .bg_image--32 {
        background-position: 27% center !important;
    }
}

/*----------------------
hero-section Default Style 
------------------------*/
.hero-section {
    .inner {
        padding-top: 100px;
        @media #{$sm-layout} {
            padding-top: 70px;
        }
        @media #{$large-mobile} {
            padding-top: 30px;
        }
        h1 {
            &.title {
                font-size: 125px;
                font-weight: 900;
                text-transform: uppercase;
                font-family: "Montserrat", sans-serif;
                font-weight: 900;
                line-height: 130px;
                @media #{$laptop-device} {
                    font-size: 100px;
                    line-height: 112px;
                }
                @media #{$lg-layout} {
                    font-size: 90px;
                    line-height: 103px;
                }
                @media #{$md-layout} {
                    font-size: 70px;
                    line-height: 82px;
                    margin-bottom: 0;
                }
                @media #{$sm-layout} {
                    font-size: 50px;
                    line-height: 70px;
                    margin-bottom: 0;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                    line-height: 50px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    &.hero-section-style-3 {
        .inner {
            > span {
                color: #1f1f25;
                font-size: 15px;
                text-transform: uppercase;
                letter-spacing: 4px;
                display: block;
                margin-bottom: 20px;
                @media #{$sm-layout} {
                    font-size: 13px;
                    margin-bottom: 10px;
                }
            }
            h1 {
                &.title {
                    color: #1f1f25;
                    font-size: 75px;
                    font-weight: 900;
                    font-family: "Montserrat", sans-serif;
                    line-height: 90px;
                    text-transform: inherit;
                    width: 70%;

                    @media #{$lg-layout} {
                        font-size: 70px;
                    }

                    @media #{$md-layout} {
                        font-size: 60px;
                        line-height: 77px;
                        width: 85%;
                    }

                    @media #{$sm-layout} {
                        font-size: 42px;
                        line-height: 57px;
                        width: 100%;
                    }

                    span {
                        color: $theme-color;
                    }
                }
            }
        }
    }
}
.hero-section-wrapper {
    &.color-white {
        .inner {
            p {
                &.description {
                    color: #ffffff;
                }
            }
        }
    }
    .service-wrapper {
        @media #{$sm-layout} {
            margin-bottom: -20px;
        }
    }
}

/*-------------------------
Personal Portfolio  
--------------------------*/
.personal-portfolio-hero-section {
    position: relative;
    @media #{$sm-layout} {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: #ffffff;
            opacity: 0.7;
        }
    }
    @media #{$md-layout} {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: #ffffff;
            opacity: 0.7;
        }
    }
    @media #{$lg-layout} {
        &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            background: #ffffff;
            opacity: 0.7;
        }
    }
}
.hero-section {
    &.personal-portfolio-hero-section,
    &.designer-portfolio {
        &.hero-section-style-3 {
            @media #{$sm-layout} {
                padding: 100px 0;
            }
            @media #{$md-layout} {
                padding: 150px 0;
            }
            @media #{$lg-layout} {
                padding: 150px 0;
                min-width: auto;
                height: auto;
            }
            .inner {
                @media #{$sm-layout} {
                    padding-top: 56px;
                }
                @media #{$md-layout} {
                    padding-top: 76px;
                }
                h1 {
                    &.title {
                        @media #{$sm-layout} {
                            font-size: 32px;
                            line-height: 49px;
                        }
                        @media #{$md-layout} {
                            font-size: 53px;
                            line-height: 70px;
                        }
                        @media #{$lg-layout} {
                            font-size: 58px;
                            line-height: 75px;
                        }
                        @media #{$laptop-device} {
                            font-size: 54px;
                            line-height: 68px;
                        }
                    }
                }
                h2 {
                    @media #{$laptop-device} {
                        font-size: 54px;
                        line-height: 1.3;
                    }
                    @media #{$sm-layout} {
                        font-size: 32px;
                        line-height: 1.3;
                    }
                    @media #{$md-layout} {
                        font-size: 53px;
                        line-height: 70px;
                    }
                    @media #{$lg-layout} {
                        font-size: 58px;
                        line-height: 75px;
                    }
                }
            }
        }
    }
    &.designer-portfolio {
        height: 700px;

        @media #{$md-layout} {
            height: auto;
        }

        @media #{$sm-layout} {
            height: auto;
        }

        &.hero-section-style-3 {
            @media #{$md-layout} {
                height: auto;
                padding: 100px 0;
            }
            @media #{$sm-layout} {
                height: auto;
                padding: 100px 0;
            }
            .inner {
                padding: 0;
                h1 {
                    &.title {
                        width: 100%;
                    }
                }
            }
            .designer-thumbnail {
                img {
                    border-radius: 100%;
                    padding: 0;
                    border: 14px solid #fff;
                    width: 394px;
                    height: 394px;
                    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1), 0 20px 40px rgba(255, 255, 255, 0.15);
                    @media #{$large-mobile} {
                        width: auto;
                        height: auto;
                    }
                    @media #{$lg-layout} {
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
}

/*=======================
    hero-section Paralax 
=========================*/

.hero-section-paralax,
.rn-paralax {
    height: 950px;
    @media #{$sm-layout} {
        height: auto;
    }
    @media #{$laptop-device} {
        height: auto;
        padding-top: 150px;
        padding-bottom: 150px;
    }
    @media #{$md-layout} {
        height: auto;
    }
    @media #{$lg-layout} {
        height: auto;
    }
}

/*--------------------
Custom Animation  
----------------------*/

@-webkit-keyframes customOne {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes customOne {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, 50%, 0);
        transform: translate3d(0, 50%, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.customOne {
    -webkit-animation-name: customOne;
    animation-name: customOne;
}

.theme-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.thumbnail img {
    border-radius: 5px;
    box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
}

.thumbnail-rounded img {
    border-radius: 100%;
    padding: 0;
    border: 7px solid whitesmoke;
    width: 300px;
    height: 300px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1), 0 20px 40px rgba(255, 255, 255, 0.15);
    @media #{$large-mobile} {
        width: auto;
        height: auto;
    }
    @media #{$lg-layout} {
        width: auto;
        height: auto;
    }
}
