/*===================
    Section Title 
====================*/

.section-title {
    margin-top: -20px;
    h2 {
        &.title {
            font-size: 60px;
            margin-bottom: 8px;

            @media #{$lg-layout} {
                font-size: 50px;
            }
            @media #{$sm-layout} {
                font-size: 36px;
                margin-bottom: 7px;
            }
        }
    }
    p {
        font-size: 18px;
        line-height: 30px;
        color: rgba(29, 29, 36, 0.75);
        a {
            color: rgba(29, 29, 36, 1);
            font-weight: 500;
            &:hover {
                color: $theme-color;
            }
        }
        br {
            @media #{$sm-layout} {
                display: none;
            }
        }
    }

    h3 {
        font-size: 36px;
        line-height: 26px;

        @media #{$sm-layout} {
            font-size: 26px;
            margin-bottom: 8px;
        }
    }

    &.service-style--3 {
        p {
            padding: 0 21%;
            font-weight: 300;
            color: #717173;
            @media #{$md-layout} {
                padding: 0 7%;
            }
            @media #{$sm-layout} {
                padding: 0;
            }
        }
        &.text-left {
            p {
                padding: 0 3% 0 0;
            }
        }
    }
}

.section-title {
    h2 {
        @media #{$sm-layout} {
            font-size: 36px;
            margin-bottom: 7px;
        }
    }
}
