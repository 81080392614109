.backto-top {
    > div {
        width: 50px;
        height: 50px;
        line-height: 49px;
        border-radius: 50%;
        background-color: #f9004d;
        text-align: center;
        overflow: hidden;
        z-index: 999 !important;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    }
    svg {
        font-size: 14px !important;
        color: white;
        width: 27px;
        height: 27px;
    }
}
